import { API, graphqlOperation } from "aws-amplify"
import * as Sentry from "@sentry/browser"
import { toast } from "react-toastify"

import {
  updatePersonalInfo,
  updateRiskProfile,
  updateKnowledgeProfile,
  updateTerms,
  updateCustomerType,
  updateValidatePersonalInfo,
  updatePhone,
  confirmCode,
  validateIdentity,
  notReceivedCode,
  updateSelectCourse,
  updateDeposit,
  updateValidationDeposit,
  updatePlatform,
  updateRiskBadTraderProfile,
} from "../graphql/mutations"

import { setTagsSentry } from "../helpers/sentry"
import { trackEvent, trackUserEvent } from "../helpers/tracker"

type Personal = {
  residenceCountry: string
  city: string
  address: string
  job: string
  isPEP: string
  addressExtension: string
  postalCode: string
  latitude: string
  longitude: string
}

type KycResponse = {
  user: User
}

const personalInfo = async (
  user: User,
  values: Personal
): Promise<KycResponse> => {
  setTagsSentry({ ...values })
  trackEvent("kyc:personal-info")

  try {
    const response = await API.graphql(
      graphqlOperation(updatePersonalInfo, {
        id: user.id,
        personalInfo: {
          ...values,
        },
      })
    )

    const updatedUser = response.data.updatePersonalInfo

    trackUserEvent("kyc:personal-info:success", {
      residenceCountry: values.residenceCountry,
      city: values.city,
    })

    return { user: updatedUser }
  } catch (error) {
    trackEvent("kyc:personal-info:error:generic")
    Sentry.setExtra("error", JSON.stringify(error))
    Sentry.captureException(error)
    toast.error(
      "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"
    )

    return { user }
  }
}

const riskProfile = async (user: User, values: any): Promise<KycResponse> => {
  setTagsSentry({ answersRisk: values })
  trackEvent("kyc:risk-profile")

  try {
    const response = await API.graphql(
      graphqlOperation(updateRiskProfile, {
        id: user.id,
        userAnswer: JSON.stringify(values),
      })
    )

    const updatedUser = response.data.updateRiskProfile

    trackEvent("kyc:risk-profile:success")

    return { user: updatedUser }
  } catch (error) {
    trackEvent("kyc:risk-profile:error:generic")
    Sentry.setExtra("error", JSON.stringify(error))
    Sentry.captureException(error)
    toast.error(
      "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"
    )

    return { user }
  }
}

const knowledgeProfile = async (
  user: User,
  values: any
): Promise<KycResponse> => {
  setTagsSentry({ answersKnowledge: values })

  trackEvent("kyc:knowledge-profile")

  try {
    const response = await API.graphql(
      graphqlOperation(updateKnowledgeProfile, {
        id: user.id,
        userAnswer: JSON.stringify(values),
      })
    )

    const updatedUser = response.data.updateKnowledgeProfile

    trackEvent("kyc:knowledge-profile:success")

    return { user: updatedUser }
  } catch (error) {
    trackEvent("kyc:knowledge-profile:error:generic")
    Sentry.setExtra("error", JSON.stringify(error))
    Sentry.captureException(error)
    toast.error(
      "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"
    )

    return { user }
  }
}

const validatePersonalInfo = async (user: User): Promise<KycResponse> => {
  trackEvent("kyc:validatePersonalInfo")

  try {
    const response = await API.graphql(
      graphqlOperation(updateValidatePersonalInfo, {
        id: user.id,
      })
    )

    const updatedUser = response.data.updateValidatePersonalInfo

    trackEvent("kyc:validatePersonalInfo:success")

    return { user: updatedUser }
  } catch (error) {
    trackEvent("kyc:validatePersonalInfo:error:generic")
    Sentry.setExtra("error", JSON.stringify(error))
    Sentry.captureException(error)
    toast.error(
      "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"
    )

    return { user }
  }
}

type ModifyPhoneType = {
  updatedPhone: boolean
  reload?: boolean | null
}

const modifyPhone = async (
  user: User,
  phone: string,
  kyc: boolean = true
): Promise<ModifyPhoneType> => {
  setTagsSentry({ phone })
  trackEvent("kyc:update-phone")

  try {
    const response = await API.graphql(
      graphqlOperation(updatePhone, {
        id: user.id,
        phone,
        kyc,
      })
    )

    const { updatedPhone, reload } = response.data.updatePhone

    const statusUpdatedPhone = updatedPhone ? "success" : "failed"
    trackUserEvent(`kyc:update-phone:${statusUpdatedPhone}`, {
      phone: phone,
    })

    if (reload) {
      window.location.reload()
    }

    return { updatedPhone, reload }
  } catch (error) {
    trackEvent("kyc:update-phone:error:generic")
    Sentry.setExtra("error", JSON.stringify(error))
    Sentry.captureException(error)
    toast.error(
      "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"
    )

    return { updatedPhone: false, reload: false }
  }
}

type ConfirmPhoneResponse = {
  confirmCode: boolean
  kycNextStep: string
  wasPhoneValidate: boolean
}

type ErrorType = {
  message: string
}

type ListErrors = {
  [key: string]: string
}

type ErrorCatchType = {
  data: any
  errors: ErrorType[]
}

const checkErrors = (listErrors: ErrorType[], defaultMessage = "") => {
  const errors: ListErrors = {
    // VALIDATION PHONE
    INVALID_CODE: "El código no es valido.",
  }

  for (const error of listErrors) {
    if (errors[error.message]) {
      return errors[error.message]
    }
  }

  return defaultMessage
}

const validateCode = async (
  user: User,
  code: string,
  kyc: boolean = true
): Promise<ConfirmPhoneResponse> => {
  setTagsSentry({ code })
  trackEvent("kyc:validate-code-phone")

  try {
    const response = await API.graphql(
      graphqlOperation(confirmCode, {
        id: user.id,
        code,
        kyc,
      })
    )

    trackUserEvent(`kyc:validate-code-phone:success`, {
      code,
    })

    return response.data.confirmCode
  } catch (error) {
    console.log(error)
    trackEvent("kyc:validate-code-phone:error:generic")
    Sentry.setExtra("error", JSON.stringify(error))
    Sentry.captureException(error)
    toast.error(
      checkErrors(
        // @ts-ignore
        error?.errors as ErrorType[],
        "No se pudo verificar tu código."
      )
    )

    return { confirmCode: false, wasPhoneValidate: false, kycNextStep: "" }
  }
}

const notCode = async (
  user: User,
  kyc: boolean = true
): Promise<KycResponse> => {
  trackEvent("kyc:validate-code-not-phone")

  try {
    const response = await API.graphql(
      graphqlOperation(notReceivedCode, {
        id: user.id,
        kyc,
      })
    )

    const result = response.data.notReceivedCode
    trackEvent(`kyc:validate-code-not-phone:success`)

    return { user: result }
  } catch (error) {
    trackEvent("kyc:validate-code-not-phone:error:generic")
    Sentry.setExtra("error", JSON.stringify(error))
    Sentry.captureException(error)
    toast.error(
      "No se puede guardar la información. Intentalo en unos minutos."
    )

    return { user }
  }
}

const startValidateIdentity = async (user: User): Promise<KycResponse> => {
  trackEvent("kyc:validate-identity")

  try {
    const response = await API.graphql(
      graphqlOperation(validateIdentity, {
        id: user.id,
        cognitoId: user.cognitoId,
      })
    )

    const result = response.data.validateIdentity
    trackEvent(`kyc:validate-identity:success`)

    return { user: result }
  } catch (error) {
    trackEvent("kyc:validate-identity:error:generic")
    Sentry.setExtra("error", JSON.stringify(error))
    Sentry.captureException(error)
    toast.error("No se pudo iniciar la validación de identidad.")

    return { user }
  }
}

const confirmRiskBadTraderProfile = async (
  user: User
): Promise<KycResponse> => {
  trackEvent("kyc:risk-bad-trader-profile")

  try {
    const response = await API.graphql(
      graphqlOperation(updateRiskBadTraderProfile, {
        id: user.id,
      })
    )

    const updatedUser = response.data.updateRiskBadTraderProfile

    trackEvent("kyc:risk-bad-trader-profile:success")

    return { user: updatedUser }
  } catch (error) {
    trackEvent("kyc:risk-bad-trader-profile:error:generic")
    Sentry.setExtra("error", JSON.stringify(error))
    Sentry.captureException(error)
    toast.error(
      "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"
    )

    return { user }
  }
}

const terms = async (user: User): Promise<KycResponse> => {
  trackEvent("kyc:terms")

  try {
    const response = await API.graphql(
      graphqlOperation(updateTerms, {
        id: user.id,
        acceptTerms: true,
      })
    )

    const updatedUser = response.data.updateTerms

    trackEvent("kyc:terms:success")

    return { user: updatedUser }
  } catch (error) {
    trackEvent("kyc:terms:error:generic")
    Sentry.setExtra("error", JSON.stringify(error))
    Sentry.captureException(error)
    toast.error(
      "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"
    )

    return { user }
  }
}

const selectCourse = async (
  user: User,
  course: string
): Promise<KycResponse> => {
  trackEvent("kyc:select-course")

  try {
    const response = await API.graphql(
      graphqlOperation(updateSelectCourse, {
        id: user.id,
        course,
      })
    )

    const updatedCourse = response.data.updateSelectCourse

    trackEvent("kyc:select-course:success")

    return { user: updatedCourse }
  } catch (error) {
    trackEvent("kyc:select-course:error:generic")
    Sentry.setExtra("error", JSON.stringify(error))
    Sentry.captureException(error)
    toast.error(
      "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"
    )

    return { user }
  }
}

const completeDeposit = async (user: User): Promise<KycResponse> => {
  trackEvent("kyc:deposit")

  try {
    const response = await API.graphql(
      graphqlOperation(updateDeposit, {
        id: user.id,
      })
    )

    const updatedUser = response.data.updateDeposit

    trackEvent("kyc:deposit:success")

    return { user: updatedUser }
  } catch (error) {
    trackEvent("kyc:deposit:error:generic")
    Sentry.setExtra("error", JSON.stringify(error))
    Sentry.captureException(error)
    toast.error(
      "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"
    )

    return { user }
  }
}

const completeValidationDeposit = async (user: User): Promise<KycResponse> => {
  trackEvent("kyc:validation-deposit")

  try {
    const response = await API.graphql(
      graphqlOperation(updateValidationDeposit, {
        id: user.id,
      })
    )

    const updatedUser = response.data.updateValidationDeposit

    trackEvent("kyc:validation-deposit:success")

    return { user: updatedUser }
  } catch (error) {
    trackEvent("kyc:validation-deposit:error:generic")
    Sentry.setExtra("error", JSON.stringify(error))
    Sentry.captureException(error)
    toast.error(
      "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"
    )

    return { user }
  }
}

const completeUpdatePlatform = async (user: User): Promise<KycResponse> => {
  trackEvent("kyc:platform")

  try {
    const response = await API.graphql(
      graphqlOperation(updatePlatform, {
        id: user.id,
      })
    )

    const updatedUser = response.data.updatePlatform

    trackEvent("kyc:platform:success")

    return { user: updatedUser }
  } catch (error) {
    trackEvent("kyc:platform:error:generic")
    Sentry.setExtra("error", JSON.stringify(error))
    Sentry.captureException(error)
    toast.error(
      "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"
    )

    return { user }
  }
}

const selectPlan = async (
  user: User,
  selectedPlan: string
): Promise<KycResponse> => {
  setTagsSentry({ selectedPlan })
  trackEvent("kyc:select-plan")

  try {
    const response = await API.graphql(
      graphqlOperation(updateCustomerType, {
        id: user.id,
        customerType: selectedPlan,
      })
    )

    const updatedUser = response.data.updateCustomerType

    trackUserEvent("kyc:select-plan:success", {
      customerType: selectedPlan,
    })

    return { user: updatedUser }
  } catch (error) {
    trackEvent("kyc:select-plan:error:generic")
    Sentry.setExtra("error", JSON.stringify(error))
    Sentry.captureException(error)
    toast.error(
      "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"
    )

    return { user }
  }
}

export default {
  updatePersonalInfo: personalInfo,
  updateRiskProfile: riskProfile,
  updateKnowledgeProfile: knowledgeProfile,
  updateRiskBadTraderProfile: confirmRiskBadTraderProfile,
  updateTerms: terms,
  updateDeposit: completeDeposit,
  updatePlatform: completeUpdatePlatform,
  selectCourse,
  updateValidationDeposit: completeValidationDeposit,
  updateValidatePersonalInfo: validatePersonalInfo,
  updateSelectPlan: selectPlan,
  updatePhone: modifyPhone,
  confirmCode: validateCode,
  notReceivedCode: notCode,
  validateIdentity: startValidateIdentity,
}

import styled from "@emotion/styled"

const buttonStyle = {
  fontFamily: "Roboto",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "16px",
  background: "#2BA770",
  letterSpacing: "0.1px",
  boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.06), 0px 1px 18px rgba(0, 0, 0, 0.04), 0px 3px 5px rgba(0, 0, 0, 0.08)",
  width: "300px",
}

const buttonStyleLoading = {
  width: "300px",
}

const ResendLink = styled.a`
  font-size: 13px;
  line-height: 140%;
  font-weight: bold;
  cursor: pointer;
  width: 300px;
  height: 45px;
  text-align: center;
  border: 1px solid #2BA770;
  background-color: #fff;
  border-radius: 6px;
  color: #2BA770;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &.disabled {
    color: #a9a9a9;
    cursor: not-allowed;
    text-decoration: none;
    background-color: transparent;
    border: none;
  }
`

const Title = styled.h1`
  max-width: 300px;
  color: #1F2425;
  text-align: center;
  font-family: Open Sans;
  font-size: 27.65px;
  font-style: normal;
  font-weight: 700;
  line-height: 116%;
  @media (max-width: 435px) {
    max-width: 205px;
  }
`

const Paragraph = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  text-align: center;
  margin: 12px 0px 30px;
  @media (max-width: 425px) {
    max-width: 284px;
  }
`

const ContainerStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10% auto;
  width: 90%;
`

const Form = styled.form`
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;

  .selected-flag {
    width: 62px;
    padding: 0;
    .flag {
      zoom: 1.25;
      right: 50%;
      margin-right: -5px;
      .arrow {
        border-left-width: 4px;
        border-right-width: 4px;
        border-top-width: 7px;
        border-top-color: #000000;
      }
    }
  }
`

export {
  buttonStyle,
  buttonStyleLoading,
  ContainerStep,
  Paragraph,
  ResendLink,
  Title,
  Form,
}

import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import { fetchCountry } from "@capitaria/capitaria-utms"
import * as Yup from "yup"

import CustomPhoneInput from "../../ui/custom-phone-input"
import { trackEventMetamap } from "../../../helpers/tracker"
import CustomButtonV2 from '../../ui/custom-button-v2'
import { buttonStyle, buttonStyleLoading, ContainerStep, Form, Paragraph, ResendLink, Title } from './styles'
import ReasonExplain from './reason'


type EditPhoneProps = {
  user: User | null
  onSubmit: (values: { phone: string }) => Promise<void>
  notCode: boolean
  isLoadingNotCode: boolean
  submitNotCode: () => Promise<void>
}

const schemaValidationPhone = Yup.object().shape({
  phone: Yup.string()
    .required("Este campo es requerido")
    .matches(
      /^[+]*(\d{1,4})?[-\s\\./0-9]*$/,
      "El número de teléfono solo puede incluir números, -, +, () y espacios"
    )
    .min(
      11,
      `El número de teléfono es muy corto`
    ),
})


const EditPhone = ({
  user,
  onSubmit,
  notCode,
  isLoadingNotCode,
  submitNotCode,
}: EditPhoneProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [countryValue, setCountryValue] = useState("cl")

  useEffect(() => {
    const getCountryValue = async () => {
      const countryCode = await fetchCountry(true)
      const countryCodeLow = countryCode.countryCode.toLowerCase()

      if (countryCodeLow !== countryValue) {
        setCountryValue(countryCodeLow)
      }
    }
    getCountryValue()
  }, [countryValue])

  const savePhone = async (values: any) => {
    setIsLoading(true)
    trackEventMetamap('phone-confirmed')
    await onSubmit(values)
    setIsLoading(false)
  }

  return (
    <ContainerStep>
      <Title>Verifiquemos tu Teléfono</Title>
      <Paragraph>
        Para continuar con la creación de tu cuenta es necesario que validemos tu teléfono para esto, enviaremos un código a tu teléfono mediante SMS
      </Paragraph>
      <Formik
        enableReinitialize
        initialValues={{
          phone: `+${user && user.phone ? user.phone : ""}`,
        }}
        validationSchema={schemaValidationPhone}
        onSubmit={savePhone}
      >
        {({
          values,
          setFieldValue,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <CustomPhoneInput
                country={countryValue}
                name="phone"
                label="Teléfono"
                preferredCountries={["cl", "pe", "uy", "mx"]}
                localization="es"
                enableSearch
                searchPlaceholder="Buscar país"
                searchNotFound="No se ha encontrado el país buscado"
                dropdownClass="country-list"
                inputClass="tel-input"
                containerClass="react-tel-input"
                inputProps={{
                  name: "phone",
                  id: "phone",
                  autoFocus: false,
                }}
                className="input-telephone"
                onBlur={handleBlur}
                errors={errors.phone}
                hasError={errors.phone && touched.phone}
                value={values.phone}
                errorMessage={errors.phone}
                onChange={(e: any) => {
                  handleChange(e)
                  setFieldValue("phone", e)
                }}
              />
              <CustomButtonV2
                type="submit"
                style={buttonStyle}
                loading={isLoading}
                loadingStyle={buttonStyleLoading}
              >
                Confirmar Teléfono
              </CustomButtonV2>
              {notCode && (
                <ResendLink onClick={submitNotCode}>
                  {!isLoadingNotCode
                    ? "No he recibido el código"
                    : "Un minuto por favor..."}
                </ResendLink>
              )}
            </Form>
          )
        }}
      </Formik>
      <ReasonExplain />
    </ContainerStep>
  )
}

export default EditPhone

import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"

import BancoEstado from "../ui/images/banco-estado-logo"
import BankModal from "./bank-modal"
import CustomModal from "../ui/custom-modal"
import PayOnline from "./pay-online"
import BancoBCI from "../ui/images/banco-bci-logo"
import { trackEvent } from "../../helpers/tracker"
import CarouselOptionsBanks from "./carousel-options-banks"
import IconToHide from "../ui/images/icon-to-hide"
import IconToDisplay from "../ui/images/icon-to-display"
import { useAuth } from "../../hooks/auth-context"

const BankTransference = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
  width: 100%;
  margin-top: 35px;
`

const ContentTitleDescription = styled.div`
  display: inline-flex;
  height: 56px;
  align-items: center;
  background-color: #fff;
  width: 100%;
  padding: 12px;
  justify-content: space-between;
  margin-top: 15px;
`
const TitleDescription = styled.h4`
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  color: #1f2425;
  line-height: 140%;
`

const Text = styled.p`
  font-family: "Open Sans";
  font-size: 0.75em;
`

const ContainerText = styled.div`
  background-color: #fff;
  width: 100%;
  height: 15.875em;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`
const ContainerTextInternational = styled.div`
  background-color: #fff;
  width: 100%;
  height: 27.875em;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`

const Tabs = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 20px;
  background-color: transparent;
  margin-bottom: 20px;
`

const Tab = styled.div`
  text-align: center;
  color: #1f2425;
  width: 217px;
  padding-bottom: 0.5rem;
  cursor: pointer;
`

const tabsList = {
  credit: "Crédito o Débito",
  transfer: "Transferencias",
}

const activeTab = {
  fontWeight: 600,
  borderBottom: "4px solid #30ab76",
}

const CapitariaBEAccount: BankAccount = {
  accountOwner: {
    label: "Beneficiario",
    value: "Capitaria Latam SPA",
  },
  rut: {
    label: "RUT",
    value: "76.257.904-9",
  },
  accountType: {
    label: "Tipo de Cuenta",
    value: "Cuenta Corriente",
  },
  accountNumber: {
    label: "N° de Cuenta",
    value: "00008079447",
  },
  email: {
    label: "Email de Depósito",
    value: "depositos@capitaria.com",
  },
}

const CapitariaBCIAccount: BankAccount = {
  accountOwner: {
    label: "Beneficiario",
    value: "Capitaria Latam SPA",
  },
  rut: {
    label: "RUT",
    value: "76.257.904-9",
  },
  accountType: {
    label: "Tipo de Cuenta",
    value: "Cuenta Corriente",
  },
  accountNumber: {
    label: "N° de Cuenta",
    value: "63516764",
  },
  email: {
    label: "Email de Depósito",
    value: "depositos@capitaria.com",
  },
}

const CapitariaBCIDollarAccount: BankAccount = {
  accountOwner: {
    label: "Beneficiario",
    value: "Capitaria Latam SPA",
  },
  rut: {
    label: "RUT",
    value: "76.257.904-9",
  },
  accountType: {
    label: "Tipo de Cuenta",
    value: "Cuenta Corriente",
  },
  accountNumber: {
    label: "N° de Cuenta",
    value: "000019667507",
  },
  swift: {
    label: "SWIFT",
    value: "CREDCLRM",
  },
  email: {
    label: "Email de Depósito",
    value: "depositos@capitaria.com",
  },
}

const CapitariaBEDollarAccount: BankAccount = {
  accountOwner: {
    label: "Beneficiario",
    value: "Capitaria Latam SPA",
  },
  rut: {
    label: "RUT",
    value: "76.257.904-9",
  },
  accountNumber: {
    label: "N° de Cuenta",
    value: "001-0-804237-0",
  },
  swift: {
    label: "SWIFT",
    value: "BECHCLRM",
  },
  intermediaryBank: {
    label: "Banco Intermediario (Opcional)",
    value: "BANK OF AMERICA",
  },
  intermediarySwift: {
    label: "SWIFT Banco Intermediario",
    value: "BOFAUS3N",
  },
  email: {
    label: "Email de Depósito",
    value: "depositos@capitaria.com",
  },
}

const KTBEAccount: BankAccount = {
  accountOwner: {
    label: "Beneficiario",
    value: "KT Financial Group Agencia en Chile",
  },
  rut: {
    label: "RUT",
    value: "59.155.280-5",
  },
  accountType: {
    label: "Tipo de Cuenta",
    value: "Cuenta Corriente",
  },
  accountNumber: {
    label: "N° de Cuenta",
    value: "00008881111",
  },
  email: {
    label: "Email de Depósito",
    value: "depositos@capitaria.com",
  },
}

const KTBEDollarAccount: BankAccount = {
  accountOwner: {
    label: "Beneficiario",
    value: "KT Agencia en Chile",
  },
  rut: {
    label: "RUT",
    value: "59.155.280-5",
  },
  accountNumber: {
    label: "N° de Cuenta",
    value: "00108042397",
  },
  swift: {
    label: "SWIFT",
    value: "BECHCLRM",
  },
  intermediaryBank: {
    label: "Banco Intermediario (Opcional)",
    value: "BANK OF AMERICA",
  },
  intermediarySwift: {
    label: "SWIFT Banco Intermediario",
    value: "BOFAUS3N",
  },
  email: {
    label: "Email de Depósito",
    value: "depositos@capitaria.com",
  },
}

const banksCapitaria = [
  {
    account: CapitariaBEAccount,
    image: <BancoEstado />,
  },
  {
    account: CapitariaBCIAccount,
    image: <BancoBCI />,
  },
  {
    account: CapitariaBEDollarAccount,
    image: <BancoEstado />,
    isDollarAccount: true,
  },
  {
    account: CapitariaBCIDollarAccount,
    image: <BancoBCI />,
    isDollarAccount: true,
  },
]

const banksKT = [
  {
    account: KTBEAccount,
    image: <BancoEstado />,
  },
  {
    account: KTBEDollarAccount,
    image: <BancoEstado />,
    isDollarAccount: true,
  },
]
const CapitariaBanksAccounts = ({
  selectedAccount,
}: {
  selectedAccount: (selectedAccount: BankAccount, logo: any) => void
}) => (
  <CarouselOptionsBanks
    listBanks={banksCapitaria}
    openModal={selectedAccount}
  />
)

const KTBanksAccounts = ({
  selectedAccount,
}: {
  selectedAccount: (selectedAccount: BankAccount, logo: any) => void
}) => (
  <CarouselOptionsBanks
    listBanks={banksKT}
    openModal={selectedAccount}
  />
)

const bankAccounts = (
  broker: string = "",
  selectedAccount: (selectedAccount: BankAccount, logo: any) => void
) => {
  if (broker === "KT Financial Group") {
    return <KTBanksAccounts selectedAccount={selectedAccount} />
  }

  return <CapitariaBanksAccounts selectedAccount={selectedAccount} />
}

type Props = {
  broker?: string
  showSkip?: boolean
  onClick?: () => void
}

const ChileDepositOnboarding = ({
  showSkip = true,
  onClick = () => { },
}: Props) => {
  const emptyAccount: BankAccount = {
    accountOwner: { label: "", value: "" },
  }
  const [account, setAccount] = useState(emptyAccount)
  const [logo, setLogo] = useState(null)
  const [broker, setBroker] = useState<string | undefined>("Capitaria")
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isClickButton, setIsClickButton] = useState(false)
  const [showSection, setShowSection] = useState("credit")
  const [isExpandedTransferNational, setIsExpandedTransferNational] = useState(false)
  const [isExpandedTransferInternational, setIsExpandedTransferInternational] = useState(false)

  const { state } = useAuth()
  const user = state.user as User

  useEffect(() => {
    setBroker(user.sf_Broker__c)
  }, [user.sf_Broker__c])

  const closeModal = () => setIsModalOpen(false)

  const clickModal = () => {
    setIsModalOpen(false)
    setIsClickButton(true)
  }

  const clickAnimationEnd = () => {
    if (isClickButton) {
      onClick()
    }
  }

  const selectedAccount = (account: BankAccount, logo: any) => {
    setIsModalOpen(true)
    setLogo(logo)
    setAccount(account)
  }
  const onClickTab = (tab: string) => () => {
    trackEvent(`deposit:tab:${tab}`)
    setShowSection(tab)
  }

  const handleClickTransferNational = () => {
    setIsExpandedTransferNational(!isExpandedTransferNational)
  }
  const handleClickTransferInternational = () => {
    setIsExpandedTransferInternational(!isExpandedTransferInternational)
  }

  return (
    <>
      <Tabs>
        {Object.entries(tabsList).map(([key, title], idx: number) => (
          <Tab
            key={`idx-tabs-deposit-${idx}`}
            style={showSection === key ? activeTab : undefined}
            onClick={onClickTab(key)}
          >
            {title}
          </Tab>
        ))}
      </Tabs>
      {showSection === "credit" && <PayOnline />}
      {showSection === "transfer" &&
        <>
          <BankTransference>

            {bankAccounts(broker, selectedAccount)}

            <ContentTitleDescription onClick={handleClickTransferNational}>
              <TitleDescription>
                Condiciones transferencias bancarias cuentas nacionales
              </TitleDescription>
              {isExpandedTransferNational ? <IconToHide /> : <IconToDisplay />}
            </ContentTitleDescription>

            {isExpandedTransferNational && (
              <ContainerText>
                <Text>
                  NO SE ACEPTARÁN DEPÓSITOS EN EFECTIVO, todo
                  depósito en efectivo será devuelto a la cuenta suscrita en el
                  contrato de apertura.
                </Text>
                <Text>
                  NO SE ACEPTARÁN DEPÓSITOS EN cheque o transferencias bancarias
                  provenientes de terceros
                  o cuentas no suscritas al contrato de apertura.
                </Text>
                <Text>
                  Todo depósito tendrá hasta 48 horas hábiles para ser reflejado en la
                  cuenta de trading.
                </Text>
                <Text>
                  La copia de transferencia deberá ser enviada como comprobante de
                  depósito a depositos@capitaria.com.
                </Text>
              </ContainerText>
            )}

            <ContentTitleDescription onClick={handleClickTransferInternational}>
              <TitleDescription>
                Condiciones transferencias bancarias cuentas internacionales
              </TitleDescription>
              {isExpandedTransferInternational ? <IconToHide /> : <IconToDisplay />}
            </ContentTitleDescription>

            {isExpandedTransferInternational && (
              <ContainerTextInternational>
                <Text>
                  NO SE ACEPTARÁN DEPÓSITOS EN EFECTIVO, todo
                  depósito en efectivo será devuelto a la cuenta suscrita en el
                  contrato de apertura.
                </Text>
                <Text>
                  NO SE ACEPTARÁN DEPÓSITOS EN cheque o transferencias bancarias
                  provenientes de terceros o cuentas no suscritas al contrato de apertura
                </Text>
                <Text>
                  Todo depósito tendrá hasta 72 horas hábiles para ser reflejado en la
                  cuenta de trading.
                </Text>
                <Text>
                  La copia de transferencia deberá ser enviada como comprobante de
                  depósito al Ejecutivo de Trading.
                </Text>
                <Text>
                  Toda transacción entre cuentas internacionales tiene un costo de
                  transferencia asociado dependiendo del banco de origen del cliente.
                </Text>
                <Text>
                  Para transferencias entre cuentas Citibank, el costo es de 8
                  dólares. Para transferencias entre Citibank y cuentas
                  internacionales, el costo es de 10 dólares.
                </Text>
                <Text>
                  La transacción puede tener costos adicionales provenientes del banco
                  de origen del cliente, antes de realizar una transacción
                  internacional, informarse con su banco acerca de los costos
                  asociados.
                </Text>
              </ContainerTextInternational>
            )}
          </BankTransference>
          <CustomModal
            title="Información para transferencia"
            isOpen={isModalOpen}
            closeModal={closeModal}
            animationEnd={clickAnimationEnd}
          >
            <BankModal
              logo={logo}
              account={account}
              showSkip={showSkip}
              onClick={clickModal}
            />
          </CustomModal>
        </>
      }
    </>
  )
}

export default ChileDepositOnboarding

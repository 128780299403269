import React, { useState } from "react"
import styled from "@emotion/styled"

import BankBox from "./bank-box"
import BankModal from "./bank-modal"
import CustomModal from "../ui/custom-modal"
import IconToHide from "../ui/images/icon-to-hide"
import IconToDisplay from "../ui/images/icon-to-display"
import CFSBLogo from "../ui/images/cfsb-logo"
import CurrencyCloudLogo from "../ui/images/currency-cloud-logo"


const BankTransference = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
  width: 100%;
  margin-top: 35px;
`

const Banks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const ContentTitleDescription = styled.div`
  display: inline-flex;
  height: 56px;
  align-items: center;
  background-color: #fff;
  width: 100%;
  padding: 12px;
  justify-content: space-between;
  margin-top: 15px;
`
const TitleDescription = styled.h4`
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  color: #1f2425;
  line-height: 140%;
`

const Text = styled.p`
  font-family: "Open Sans";
  font-size: 0.75em;
`

const ContainerText = styled.div`
  background-color: #fff;
  width: 100%;
  height: 27.875em;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`

const CommunityFederalSavingsBankAccount: BankAccount = {
  accountOwner: {
    label: "Destinatario",
    value: "KT Financial Group Agencia en Chile",
  },
  swift: {
    label: "SWIFT",
    value: "CMFGUS33",
  },
  intermediaryBank: {
    label: "Nombre Banco",
    value: "Community Federal Savings Bank",
  },
  dollarAccount: {
    label: "Numero de cuenta",
    value: "8339296174",
  },
  sucursal: {
    label: "Dirección del Banco",
    value: "810 Seventh Avenue, New York, NY 10019, US",
  },
  email: {
    label: "Email de Depósito",
    value: "depositos@capitaria.com",
  },
}

const CurrencyCloudBankAccount: BankAccount = {
  accountOwner: {
    label: "Destinatario",
    value: "KT Financial Group Agencia en Chile",
  },
  accountNumber: {
    label: "IBAN",
    value: "GB95TCCL00997944610567",
  },
  swift: {
    label: "SWIFT",
    value: "TCCLGB31",
  },
  intermediaryBank: {
    label: "Nombre Banco",
    value: "The Currency Cloud Limited",
  },
  sucursal: {
    label: "Dirección del Banco",
    value: "12 Stewart Street, The Steward Building, London, E1 6FQ, GB",
  },
  email: {
    label: "Email de Depósito",
    value: "depositos@capitaria.com",
  },
}

type Props = {
  showSkip?: boolean
  onClick?: () => void
}

const OtherCountryDeposit = ({
  showSkip = true,
  onClick = () => { },
}: Props) => {
  const emptyAccount: BankAccount = {
    accountOwner: { label: "", value: "" },
  }
  const [account, setAccount] = useState(emptyAccount)
  const [logo, setLogo] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isClickButton, setIsClickButton] = useState(false)
  const [isExpandedTransfer, setIsExpandedTransfer] = useState(false)

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const clickModal = () => {
    setIsModalOpen(false)
    setIsClickButton(true)
  }

  const clickAnimationEnd = () => {
    if (isClickButton) {
      onClick()
    }
  }

  const selectedAccount = (selectedAccount: BankAccount, logo: any) => {
    setIsModalOpen(true)
    setLogo(logo)
    setAccount(selectedAccount)
  }

  const handleClickTransfer = () => {
    setIsExpandedTransfer(!isExpandedTransfer)
  }

  return (
    <>
      <BankTransference>
        <Banks>
          <BankBox
            onClick={selectedAccount}
            account={CommunityFederalSavingsBankAccount}
            isDollarAccount
          >
            <CFSBLogo />
          </BankBox>

          <BankBox
            onClick={selectedAccount}
            account={CurrencyCloudBankAccount}
            isEuroAccount
          >
            <CurrencyCloudLogo />
          </BankBox>
        </Banks>

        <ContentTitleDescription onClick={handleClickTransfer}>
          <TitleDescription>
            Condiciones transferencias bancarias cuentas internacionales
          </TitleDescription>
          {isExpandedTransfer ? <IconToHide /> : <IconToDisplay />}
        </ContentTitleDescription>

        {isExpandedTransfer && (
          <ContainerText>
            <Text>NO SE ACEPTARÁN DEPÓSITOS EN EFECTIVO, todo
              depósito en efectivo será devuelto a la cuenta suscrita en el
              contrato de apertura.
            </Text>
            <Text>
              NO SE ACEPTARÁN DEPÓSITOS EN cheque o transferencias bancarias
              provenientes de terceros o cuentas no suscritas al contrato de apertura
            </Text>
            <Text>
              Todo depósito tendrá hasta 72 horas hábiles para ser reflejado en la
              cuenta de trading.
            </Text>
            <Text>
              La copia de transferencia deberá ser enviada como comprobante de
              depósito al Ejecutivo de Trading.
            </Text>
            <Text>
              Toda transacción entre cuentas internacionales tiene un costo de
              transferencia asociado dependiendo del banco de origen del cliente.
            </Text>
            <Text>
              La transacción puede tener costos adicionales provenientes del banco
              de origen del cliente, antes de realizar una transacción
              internacional, informarse con su banco acerca de los costos
              asociados.
            </Text>
          </ContainerText>
        )}
      </BankTransference>

      <CustomModal
        title="Información para transferencia"
        isOpen={isModalOpen}
        closeModal={closeModal}
        animationEnd={clickAnimationEnd}
      >
        <BankModal
          logo={logo}
          account={account}
          showSkip={showSkip}
          onClick={clickModal}
        />
      </CustomModal>
    </>
  )
}

export default OtherCountryDeposit

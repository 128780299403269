import React from "react"
import styled from "@emotion/styled"

const CFSB = styled.img`
  width: 150px;
  height: 60px;
`

const urlImage =
  'https://capitariaplatformmaster-master.s3.us-east-2.amazonaws.com/public/community-federal-savings-bank.png'

const CFSBLogo = () => <CFSB src={urlImage} alt="CFSB" />

export default CFSBLogo

import React, { useEffect, useState } from "react"
import { Global, css } from "@emotion/core"
import { navigate } from "@reach/router"
import { toast } from "react-toastify"

import { trackEventMetamap } from "../../helpers/tracker"
import { useAuth } from "../../hooks/auth-context"
import KYC from "../../services/kyc"
import StateValidatePhone from "./phone/state-validate-phone"
import EditPhone from "./phone/edit-phone"
import ConfirmCode from "./phone/confirm-code"

const styleCss = css`
  .input-telephone {
    width: 335px;
    @media (max-width: 450px) {
      width: 300px;
    }
  }

  div.react-tel-input input.tel-input {
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    height: 48px;
    width: 265px;
    @media (max-width: 450px) {
      width: 230px;
    }
  }
`

type EditPhoneProps = {
  user: User | null
  onSubmit: (values: { phone: string }) => Promise<void>
  notCode: boolean
  isLoadingNotCode: boolean
  submitNotCode: () => Promise<void>
}

type ConfirmCodeProps = {
  phoneState: string
  seconds: number
  isWaiting: boolean
  onClickResendCode: () => void
  onSendCode: (values: { code: string }) => Promise<void>
  notCode: boolean
  isLoadingNotCode: boolean
  submitNotCode: () => Promise<void>
}

type ConfirmPhoneProps = EditPhoneProps & ConfirmCodeProps & {
  updatePhone: boolean
}

const ConfirmPhone = ({
  user,
  updatePhone,
  onSubmit,
  phoneState,
  seconds,
  isWaiting,
  onClickResendCode,
  onSendCode,
  notCode,
  isLoadingNotCode,
  submitNotCode,
}: ConfirmPhoneProps) => {
  return (
    <>
      {
        updatePhone ?
          <ConfirmCode
            phoneState={phoneState}
            seconds={seconds}
            isWaiting={isWaiting}
            onClickResendCode={onClickResendCode}
            onSendCode={onSendCode}
            notCode={notCode}
            isLoadingNotCode={isLoadingNotCode}
            submitNotCode={submitNotCode}
          /> :
          <EditPhone
            user={user}
            onSubmit={onSubmit}
            notCode={notCode}
            isLoadingNotCode={isLoadingNotCode}
            submitNotCode={submitNotCode}
          />
      }
    </>
  )
}

const TIMER = 30

type Props = {
  kyc: boolean
}

const ValidatePhone = ({ kyc }: Props) => {
  const { state } = useAuth()
  const { user, setUser } = state

  const [updatePhone, setUpdatePhone] = useState(false)
  const [phoneState, setPhoneState] = useState("")

  const [seconds, setSeconds] = useState(TIMER)
  const [isWaiting, setIsWaiting] = useState(false)

  const [notCode, setNotCode] = useState(false)
  const [isLoadingNotCode, setIsLoadingNotCode] = useState(false)

  useEffect(() => {
    let interval: any = null

    if (isWaiting) {
      if (seconds === 0) {
        clearInterval(interval)
        setIsWaiting(false)
        setSeconds(TIMER)
      } else {
        interval = setInterval(() => setSeconds(seconds => seconds - 1), 1000)
      }
    }
    return () => clearInterval(interval)
  }, [isWaiting, seconds])

  const onClickResendCode = () => {
    if (!isWaiting) {
      setIsWaiting(true)
      trackEventMetamap('resend code validation phone number')
      onSubmit({ phone: phoneState })
    }
  }

  const onSubmit = async (values: { phone: string }) => {
    const phone: string = values.phone.replace('+', '')
    const result = await KYC.updatePhone(user as User, phone, kyc)
    setPhoneState(values.phone)
    if (result.updatedPhone) {
      setUser({
        ...(user as User),
        phone,
      })
      setUpdatePhone(true)
    } else {
      if (result.reload !== true) {
        toast.error(
          "No se puede volver a enviar el código"
        )
        setNotCode(true)
      }
    }
  }

  const onSendCode = async (values: { code: string }) => {
    try {
      const result = await KYC.confirmCode(user as User, values.code, kyc)
      console.log(result.confirmCode)
      if (result.wasPhoneValidate) {
        setUser({
          ...(user as User),
          confirmPhone: result.confirmCode,
          wasPhoneValidate: result.wasPhoneValidate,
          kycNextStep: result.kycNextStep,
        })
        if (kyc) {
          navigate(`/${result.kycNextStep}`)
        }
      }

    } catch (error) {
      console.log(error)
    }

  }

  const submitNotCode = async () => {
    if (!isLoadingNotCode) {
      setIsLoadingNotCode(true)
      trackEventMetamap('code-not-received')
      const result = await KYC.notReceivedCode(user as User, kyc)

      setUser(result.user)
      if (kyc) {
        navigate(`/${result.user.kycNextStep}`)
      }
      setIsLoadingNotCode(false)
    }
  }

  return (
    <>
      <Global styles={styleCss} />
      {
        user && user.wasPhoneValidate ?
          <StateValidatePhone confirmPhone={user.confirmPhone} /> :
          <ConfirmPhone
            user={user}
            updatePhone={updatePhone}
            onSubmit={onSubmit}
            phoneState={phoneState}
            seconds={seconds}
            isWaiting={isWaiting}
            onClickResendCode={onClickResendCode}
            onSendCode={onSendCode}
            notCode={notCode}
            isLoadingNotCode={isLoadingNotCode}
            submitNotCode={submitNotCode}
          />

      }
    </>
  )
}

export default ValidatePhone

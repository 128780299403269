import React from "react"
import styled from "@emotion/styled"
import { navigate } from "@reach/router"

import WarningIcon from "../../ui/images/warning-icon"
import IconComplete from "./../images/complete"
import CustomButtonV2 from "../../ui/custom-button-v2"
import { buttonStyle, buttonStyleLoading, Title } from "./styles"


const ContainerValidatePhone = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 300px;
  margin: 60px auto;
`

const TextValidationStatus = styled.div`
  margin: 3% 0;
`

type StateValidatePhoneProps = {
  confirmPhone?: boolean
}

const StateValidatePhone = ({ confirmPhone }: StateValidatePhoneProps) => (
  <ContainerValidatePhone>
    <Title>Numero telefónico</Title>
    <div>{confirmPhone ? <IconComplete /> : <WarningIcon />}</div>
    <TextValidationStatus>
      Numero telefónico {confirmPhone ? '' : 'no'} validado
    </TextValidationStatus>
    {
      !confirmPhone &&
      <div>Lo validaremos más adelante, por ahora continúa con el resto de las validaciones</div>
    }
    <CustomButtonV2
      style={buttonStyle}
      loadingStyle={buttonStyleLoading}
      onClick={() => navigate('/home')}
    >
      Volver
    </CustomButtonV2>
  </ContainerValidatePhone>
)

export default StateValidatePhone

import React from "react"
import styled from "@emotion/styled"

const ContainerReasons = styled.div`
  max-width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  @media (max-width: 425px) {
    max-width: 90%;
  }
  @media (max-width: 768px) {
    max-width: 60%;
  }
  @media (min-width: 1024px) {
    max-width: 30%;
  }
`

const WhyQuestion = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  font-weight: 600;
  font-family: Roboto;
`

const Reason = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-family: Roboto;
  text-align: center;
`


const ReasonExplain = () => (
  <ContainerReasons>
    <WhyQuestion>
      ¿Porque necesitamos validar tu teléfono?
    </WhyQuestion>
    <Reason>
      Tu teléfono es la forma más rápida de contactarnos contigo para ayudarte en todo momento y es parte fundamental para validar tu información personal.
    </Reason>
  </ContainerReasons>
)

export default ReasonExplain

import React from "react"
import styled from "@emotion/styled"

const CurrencyCloud = styled.img`
  width: 115px;
  height: 63px;
`

const urlImage =
  'https://capitariaplatformmaster-master.s3.us-east-2.amazonaws.com/public/currency-cloud.png'

const CurrencyCloudLogo = () => <CurrencyCloud src={urlImage} alt="CurrencyCloud" />

export default CurrencyCloudLogo

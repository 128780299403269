import React, { useState } from "react"
import styled from "@emotion/styled"
import { Formik } from "formik"
import * as Yup from "yup"

import { trackEventMetamap } from "../../../helpers/tracker"
import { buttonStyle, buttonStyleLoading, ContainerStep, Form, Paragraph, ResendLink, Title } from "./styles"
import OtpInput from "../../ui/opt-input"
import CustomButtonV2 from "../../ui/custom-button-v2"
import ReasonExplain from "./reason"

const labelCSS = {
  fontSize: "18px",
  width: "100%",
  textAlign: "center",
  marginBottom: "13px",
}

const ContentInputsCodeConfirmPhone = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 189px;
  margin-bottom: 5%;

  & > * {
    width: 42px;
    min-height: 43px;
    flex-shrink: 0;
    border-radius: 5px;
  }
`

const BasicTextGray = styled.p`
  color: #363F41;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-decoration: none !important ;
`

const BoldText = styled.strong`
  color: #363F41;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-decoration-line: underline;
`

type ConfirmCodeProps = {
  phoneState: string
  seconds: number
  isWaiting: boolean
  onClickResendCode: () => void
  onSendCode: (values: { code: string }) => Promise<void>
  notCode: boolean
  isLoadingNotCode: boolean
  submitNotCode: () => Promise<void>
}

const ConfirmCode = ({
  phoneState,
  seconds,
  isWaiting,
  onClickResendCode,
  onSendCode,
  notCode,
  isLoadingNotCode,
  submitNotCode,
}: ConfirmCodeProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const sendCode = async (values: any) => {
    setIsLoading(true)
    trackEventMetamap('sending-verification-code')
    await onSendCode(values)
    setIsLoading(false)
  }

  return (
    <ContainerStep>
      <Title>Verifiquemos tu Teléfono</Title>
      <Paragraph>
        Hemos enviado tu código via SMS al teléfono {phoneState}
      </Paragraph>
      <Formik
        enableReinitialize
        initialValues={{
          code: "",
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().required("Este campo es requerido"),
        })}
        onSubmit={sendCode}
      >
        {({
          values,
          setFieldValue,
          touched,
          errors,
          handleSubmit,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <ContentInputsCodeConfirmPhone>
                <OtpInput
                  name="code"
                  label="Ingresa el código enviado"
                  styleLabel={labelCSS}
                  value={values.code}
                  valueLength={4}
                  onChange={(value) => { setFieldValue("code", value) }}
                  hasError={errors.code && touched.code}
                  errorMessage={errors.code}
                />
              </ContentInputsCodeConfirmPhone>
              <CustomButtonV2
                type="submit"
                style={buttonStyle}
                loading={isLoading}
                loadingStyle={buttonStyleLoading}
              >
                Confirmar Código
              </CustomButtonV2>
              <br />
              {!notCode && !isLoading && (
                <ResendLink className={isWaiting ? "disabled" : ""} onClick={onClickResendCode}>
                  {!isWaiting ? (
                    <>
                      <BasicTextGray>¿No has recibido ningún código?</BasicTextGray>
                      <BoldText>¡Inténtalo de nuevo!</BoldText>
                    </>
                  ) : (
                    <>Puedes volver a enviar tu código en {seconds} segundo
                      {
                        seconds !== 1 ? "s" : ""
                      }
                    </>
                  )}
                </ResendLink>
              )}
              {notCode && (
                <ResendLink onClick={submitNotCode}>
                  {!isLoadingNotCode
                    ? "No he recibido el código"
                    : "Un minuto por favor..."}
                </ResendLink>
              )}
            </Form>
          )
        }
        }
      </Formik>
      <ReasonExplain />
    </ContainerStep>
  )
}

export default ConfirmCode

import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"

import { useAuth } from "../../hooks/auth-context"
import KYC from "../../services/kyc"

import ChileDepositOnboarding from "./chile-deposit-onboarding"
import PeruDeposit from "./peru-deposit"
import UruguayDeposit from "./uruguay-deposit"
import OtherCountryDeposit from "./other-country-deposit"
import ModalPlans from "../shared/modal-plans"
import MobileNavbarDeposit from "../ui/util-dropdown"
import FlagPeru from "../ui/images/flag-peru"
import FlagChile from "../ui/images/flag-chile"
import FlagUruguay from "../ui/images/flag-uruguay"
import FlagMexico from "../ui/images/flag-mexico"
import GlobalSimbol from "../ui/images/global-simbol"
import { navigate } from "@reach/router"
import CustomButtonV2 from "../ui/custom-button-v2"
import MexicoDeposit from "../nuvei/mexico-deposit"


const Container = styled.div`
  display: flex;
  justify-content: center;
`

const DepositWrapper = styled.div`
  padding: 3rem 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 690px;
  @media (max-width: 812px) {
    max-width: 368px;
  }
`

const WelcomeProspect = styled.div`
  line-height: 24px;
  width: 100%;
  min-height: 96px;
  height: auto;
  border-radius: 5px;
  margin: auto;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  padding: 19px;
  @media (max-width: 812px) {
    width: 100%;
    flex-direction: column;
    height: auto;
  }

`

const RowMessage = styled.div`
  color: #1F2425;
  margin-bottom: 15px;
`

const RowButton = styled.div`
  width: 200px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 3.284px;
  border: 1px solid #2BA770;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 812px) {
    margin-top: 1.5rem;
    margin-bottom: 0.7rem;
  }
`

const ButtonAccountsType = ({ onClick, href, text }: CustomLink) => {
  const Button = styled.a`
    color: #303A3E;
    border-radius: 5px;
    border: none;
    height: 44px;
    width: 128px;
    font-family: "Roboto";
    -webkit-text-decoration: none;
    text-decoration: none;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 812px) {
      margin-left: 0;
    }
  `

  return <Button onClick={onClick} href={href}>
    {text}
  </Button>
}

const Tittle = styled.h3`
  width: 400px;
  color: #1F2425;
  text-align: center;
  font-family: "Open Sans";
  font-size: 27.65px;
  font-style: normal;
  font-weight: 700;
  line-height: 116%;
  @media (max-width: 812px) {
    width: 280px;
  }
`

const buttonStyle = {
  marginTop: "1rem",
  width: "298px",
  height: "50px",
  "@media (max-width: 425px)": {
    width: "213px",
    fontSize: "18px",
    lineHeight: "138.889%",
    textAlign: "center",
  },
}

const loadingButtonStyle = buttonStyle

const DepositAfter = styled.a`
  cursor: pointer;
  margin-top: 10px;
  text-decoration: underline;
`
type Props = {
  broker?: string
  showSkip?: boolean
  onClick?: () => void
}

type CustomLink = {
  href?: string
  text?: string
  modal?: boolean
  onClick?: () => void
}

const UserCountryDepositOnboarding = ({
  showSkip = false,
  onClick = () => { },
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [country, setCountry] = useState("Chile")

  const { state } = useAuth()
  const user = state.user as User

  useEffect(() => {
    setCountry(user.residenceCountry)
  }, [user.residenceCountry])


  const items = [
    {
      id: "Chile",
      text: "Opciones de depósito para Chile",
      iconFlag: <FlagChile />,
    },
    {
      id: "Peru",
      text: "Opciones de depósito para Peru",
      iconFlag: <FlagPeru width="15" height="15" />,
    },
    {
      id: "Uruguay",
      text: "Opciones de depósito para Uruguay",
      iconFlag: <FlagUruguay />,
    },
    {
      id: "Mexico",
      text: "Opciones de depósito para Mexico",
      iconFlag: <FlagMexico />,
    },
    {
      id: "Global",
      text: "Opciones de depósito para Otros Países",
      iconFlag: <GlobalSimbol />,
    },
  ]

  let UserCountryDeposit = OtherCountryDeposit

  const listCountryDeposit = {
    Chile: ChileDepositOnboarding,
    Peru: PeruDeposit,
    Uruguay: UruguayDeposit,
    Mexico: MexicoDeposit,
  }

  if (listCountryDeposit.hasOwnProperty(country)) {
    UserCountryDeposit = listCountryDeposit[country as keyof typeof listCountryDeposit]
  }


  const link: CustomLink = {
    text: "Ver tipos de cuentas",
    modal: true,
    onClick: () => setIsModalOpen(true)
  }

  const onSubmit = async () => {
    setIsLoading(true)

    const result = await KYC.updateDeposit(user)
    state.setUser(result.user)
    setIsLoading(false)
    navigate(`/${result.user.kycNextStep}`)
  }
  return (
    <Container>
      <DepositWrapper>
        <ModalPlans
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
        />
        <WelcomeProspect>
          <Tittle>Deposita fondos en tu cuenta</Tittle>
          <RowMessage>
            ¿Ya conoces nuestros tipos de cuentas?
          </RowMessage>
          <RowButton>
            {link.href && <ButtonAccountsType {...link} />}
            {link.modal && <ButtonAccountsType text={link.text} onClick={link.onClick} />}
          </RowButton>
        </WelcomeProspect>
        <MobileNavbarDeposit
          country={country}
          items={items}
          onchange={(country: any) => { setCountry(country.id) }}
          defaultCountry="Global"
        />
        {<UserCountryDeposit onClick={onClick} />}
        {showSkip && (
          <>
            <CustomButtonV2
              style={buttonStyle}
              onClick={onSubmit}
              loading={isLoading}
              loadingStyle={loadingButtonStyle}
            >
              Ya deposité
            </CustomButtonV2>
            <DepositAfter onClick={onSubmit}>Depositaré despúes</DepositAfter>
          </>
        )}
      </DepositWrapper>
    </Container>
  )
}

export default UserCountryDepositOnboarding

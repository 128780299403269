/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updatePhone = /* GraphQL */ `
  mutation UpdatePhone($id: ID!, $phone: String!, $kyc: Boolean) {
    updatePhone(id: $id, phone: $phone, kyc: $kyc) {
      updatedPhone
      reload
    }
  }
`;
export const confirmCode = /* GraphQL */ `
  mutation ConfirmCode($id: ID!, $code: String!, $kyc: Boolean) {
    confirmCode(id: $id, code: $code, kyc: $kyc) {
      confirmPhone
      wasPhoneValidate
      kycNextStep
    }
  }
`;
export const notReceivedCode = /* GraphQL */ `
  mutation NotReceivedCode($id: ID!, $kyc: Boolean) {
    notReceivedCode(id: $id, kyc: $kyc) {
      id
      cognitoId
      identityId
      salesforceId
      givenName
      familyName
      email
      phone
      birth
      dni
      signUpCountry
      originCountry
      residenceCountry
      city
      address
      job
      liveInUSA
      isPEP
      riskProfile
      riskPoints
      knowledgeProfile
      knowledgePoints
      userProfileSignature
      isKycApproved
      acceptTerms
      userAcceptTermsSignature
      customerStatus
      customerType
      customerCategory
      kycNextStep
      sf_Broker__c
      subaccounts {
        total_deposit_clp
        total_ransom_pen
        total_deposit_usd
        total_ransom_clp
        total_ransom_usd
        total_deposit_pen
        name
        currency
      }
      wasIdFileUploaded
      userIdFileSignature
      frontSideIdFilePath
      backSideIdFilePath
      wasAddressFileUploaded
      userAddressFileSignature
      addressFilePath
      wasCardFileUploaded
      userCardFileSignature
      cardFilePath
      wasLivenessFileUploaded
      confirmPhone
      wasPhoneValidate
      wasCreatedInMarketingCloud
      wasCreatedInSalesforce
      needKyc
      utmSource
      utmMedium
      utmCampaign
      utmId
      createdAt
      updatedAt
      validatePersonalInfo
    }
  }
`;
export const validateIdentity = /* GraphQL */ `
  mutation ValidateIdentity($id: ID!, $cognitoId: ID!) {
    validateIdentity(id: $id, cognitoId: $cognitoId) {
      id
      cognitoId
      identityId
      salesforceId
      givenName
      familyName
      email
      phone
      birth
      dni
      signUpCountry
      originCountry
      residenceCountry
      city
      address
      job
      liveInUSA
      isPEP
      riskProfile
      riskPoints
      knowledgeProfile
      knowledgePoints
      userProfileSignature
      isKycApproved
      acceptTerms
      userAcceptTermsSignature
      customerStatus
      customerType
      customerCategory
      kycNextStep
      sf_Broker__c
      subaccounts {
        total_deposit_clp
        total_ransom_pen
        total_deposit_usd
        total_ransom_clp
        total_ransom_usd
        total_deposit_pen
        name
        currency
      }
      wasIdFileUploaded
      userIdFileSignature
      frontSideIdFilePath
      backSideIdFilePath
      wasAddressFileUploaded
      userAddressFileSignature
      addressFilePath
      wasCardFileUploaded
      userCardFileSignature
      cardFilePath
      wasLivenessFileUploaded
      confirmPhone
      wasPhoneValidate
      wasCreatedInMarketingCloud
      wasCreatedInSalesforce
      needKyc
      utmSource
      utmMedium
      utmCampaign
      utmId
      createdAt
      updatedAt
      validatePersonalInfo
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent($id: ID!, $event: EventInput!) {
    createEvent(id: $id, event: $event)
  }
`;
export const updateCustomerType = /* GraphQL */ `
  mutation UpdateCustomerType($id: ID!, $customerType: CustomerType!) {
    updateCustomerType(id: $id, customerType: $customerType) {
      id
      cognitoId
      identityId
      salesforceId
      givenName
      familyName
      email
      phone
      birth
      dni
      signUpCountry
      originCountry
      residenceCountry
      city
      address
      job
      liveInUSA
      isPEP
      riskProfile
      riskPoints
      knowledgeProfile
      knowledgePoints
      userProfileSignature
      isKycApproved
      acceptTerms
      userAcceptTermsSignature
      customerStatus
      customerType
      customerCategory
      kycNextStep
      sf_Broker__c
      subaccounts {
        total_deposit_clp
        total_ransom_pen
        total_deposit_usd
        total_ransom_clp
        total_ransom_usd
        total_deposit_pen
        name
        currency
      }
      wasIdFileUploaded
      userIdFileSignature
      frontSideIdFilePath
      backSideIdFilePath
      wasAddressFileUploaded
      userAddressFileSignature
      addressFilePath
      wasCardFileUploaded
      userCardFileSignature
      cardFilePath
      wasLivenessFileUploaded
      confirmPhone
      wasPhoneValidate
      wasCreatedInMarketingCloud
      wasCreatedInSalesforce
      needKyc
      utmSource
      utmMedium
      utmCampaign
      utmId
      createdAt
      updatedAt
      validatePersonalInfo
    }
  }
`;
export const updatePersonalInfo = /* GraphQL */ `
  mutation UpdatePersonalInfo($id: ID!, $personalInfo: PersonalInfo!) {
    updatePersonalInfo(id: $id, personalInfo: $personalInfo) {
      id
      cognitoId
      identityId
      salesforceId
      givenName
      familyName
      email
      phone
      birth
      dni
      signUpCountry
      originCountry
      residenceCountry
      city
      address
      job
      liveInUSA
      isPEP
      riskProfile
      riskPoints
      knowledgeProfile
      knowledgePoints
      userProfileSignature
      isKycApproved
      acceptTerms
      userAcceptTermsSignature
      customerStatus
      customerType
      customerCategory
      kycNextStep
      sf_Broker__c
      subaccounts {
        total_deposit_clp
        total_ransom_pen
        total_deposit_usd
        total_ransom_clp
        total_ransom_usd
        total_deposit_pen
        name
        currency
      }
      wasIdFileUploaded
      userIdFileSignature
      frontSideIdFilePath
      backSideIdFilePath
      wasAddressFileUploaded
      userAddressFileSignature
      addressFilePath
      wasCardFileUploaded
      userCardFileSignature
      cardFilePath
      wasLivenessFileUploaded
      confirmPhone
      wasPhoneValidate
      wasCreatedInMarketingCloud
      wasCreatedInSalesforce
      needKyc
      utmSource
      utmMedium
      utmCampaign
      utmId
      createdAt
      updatedAt
      validatePersonalInfo
    }
  }
`;
export const updateSelectCourse = /* GraphQL */ `
  mutation UpdateSelectCourse($id: ID!, $course: String!) {
    updateSelectCourse(id: $id, course: $course) {
      id
      cognitoId
      identityId
      salesforceId
      givenName
      familyName
      email
      phone
      birth
      dni
      signUpCountry
      originCountry
      residenceCountry
      city
      address
      job
      liveInUSA
      isPEP
      riskProfile
      riskPoints
      knowledgeProfile
      knowledgePoints
      userProfileSignature
      isKycApproved
      acceptTerms
      userAcceptTermsSignature
      customerStatus
      customerType
      customerCategory
      kycNextStep
      sf_Broker__c
      subaccounts {
        total_deposit_clp
        total_ransom_pen
        total_deposit_usd
        total_ransom_clp
        total_ransom_usd
        total_deposit_pen
        name
        currency
      }
      wasIdFileUploaded
      userIdFileSignature
      frontSideIdFilePath
      backSideIdFilePath
      wasAddressFileUploaded
      userAddressFileSignature
      addressFilePath
      wasCardFileUploaded
      userCardFileSignature
      cardFilePath
      wasLivenessFileUploaded
      confirmPhone
      wasPhoneValidate
      wasCreatedInMarketingCloud
      wasCreatedInSalesforce
      needKyc
      utmSource
      utmMedium
      utmCampaign
      utmId
      createdAt
      updatedAt
      validatePersonalInfo
    }
  }
`;
export const updateValidationDeposit = /* GraphQL */ `
  mutation UpdateValidationDeposit($id: ID!) {
    updateValidationDeposit(id: $id) {
      id
      cognitoId
      identityId
      salesforceId
      givenName
      familyName
      email
      phone
      birth
      dni
      signUpCountry
      originCountry
      residenceCountry
      city
      address
      job
      liveInUSA
      isPEP
      riskProfile
      riskPoints
      knowledgeProfile
      knowledgePoints
      userProfileSignature
      isKycApproved
      acceptTerms
      userAcceptTermsSignature
      customerStatus
      customerType
      customerCategory
      kycNextStep
      sf_Broker__c
      subaccounts {
        total_deposit_clp
        total_ransom_pen
        total_deposit_usd
        total_ransom_clp
        total_ransom_usd
        total_deposit_pen
        name
        currency
      }
      wasIdFileUploaded
      userIdFileSignature
      frontSideIdFilePath
      backSideIdFilePath
      wasAddressFileUploaded
      userAddressFileSignature
      addressFilePath
      wasCardFileUploaded
      userCardFileSignature
      cardFilePath
      wasLivenessFileUploaded
      confirmPhone
      wasPhoneValidate
      wasCreatedInMarketingCloud
      wasCreatedInSalesforce
      needKyc
      utmSource
      utmMedium
      utmCampaign
      utmId
      createdAt
      updatedAt
      validatePersonalInfo
    }
  }
`;
export const updatePlatform = /* GraphQL */ `
  mutation UpdatePlatform($id: ID!) {
    updatePlatform(id: $id) {
      id
      cognitoId
      identityId
      salesforceId
      givenName
      familyName
      email
      phone
      birth
      dni
      signUpCountry
      originCountry
      residenceCountry
      city
      address
      job
      liveInUSA
      isPEP
      riskProfile
      riskPoints
      knowledgeProfile
      knowledgePoints
      userProfileSignature
      isKycApproved
      acceptTerms
      userAcceptTermsSignature
      customerStatus
      customerType
      customerCategory
      kycNextStep
      sf_Broker__c
      subaccounts {
        total_deposit_clp
        total_ransom_pen
        total_deposit_usd
        total_ransom_clp
        total_ransom_usd
        total_deposit_pen
        name
        currency
      }
      wasIdFileUploaded
      userIdFileSignature
      frontSideIdFilePath
      backSideIdFilePath
      wasAddressFileUploaded
      userAddressFileSignature
      addressFilePath
      wasCardFileUploaded
      userCardFileSignature
      cardFilePath
      wasLivenessFileUploaded
      confirmPhone
      wasPhoneValidate
      wasCreatedInMarketingCloud
      wasCreatedInSalesforce
      needKyc
      utmSource
      utmMedium
      utmCampaign
      utmId
      createdAt
      updatedAt
      validatePersonalInfo
    }
  }
`;
export const updateRiskProfile = /* GraphQL */ `
  mutation UpdateRiskProfile($id: ID!, $userAnswer: AWSJSON!) {
    updateRiskProfile(id: $id, userAnswer: $userAnswer) {
      id
      cognitoId
      identityId
      salesforceId
      givenName
      familyName
      email
      phone
      birth
      dni
      signUpCountry
      originCountry
      residenceCountry
      city
      address
      job
      liveInUSA
      isPEP
      riskProfile
      riskPoints
      knowledgeProfile
      knowledgePoints
      userProfileSignature
      isKycApproved
      acceptTerms
      userAcceptTermsSignature
      customerStatus
      customerType
      customerCategory
      kycNextStep
      sf_Broker__c
      subaccounts {
        total_deposit_clp
        total_ransom_pen
        total_deposit_usd
        total_ransom_clp
        total_ransom_usd
        total_deposit_pen
        name
        currency
      }
      wasIdFileUploaded
      userIdFileSignature
      frontSideIdFilePath
      backSideIdFilePath
      wasAddressFileUploaded
      userAddressFileSignature
      addressFilePath
      wasCardFileUploaded
      userCardFileSignature
      cardFilePath
      wasLivenessFileUploaded
      confirmPhone
      wasPhoneValidate
      wasCreatedInMarketingCloud
      wasCreatedInSalesforce
      needKyc
      utmSource
      utmMedium
      utmCampaign
      utmId
      createdAt
      updatedAt
      validatePersonalInfo
    }
  }
`;
export const updateKnowledgeProfile = /* GraphQL */ `
  mutation UpdateKnowledgeProfile($id: ID!, $userAnswer: AWSJSON!) {
    updateKnowledgeProfile(id: $id, userAnswer: $userAnswer) {
      id
      cognitoId
      identityId
      salesforceId
      givenName
      familyName
      email
      phone
      birth
      dni
      signUpCountry
      originCountry
      residenceCountry
      city
      address
      job
      liveInUSA
      isPEP
      riskProfile
      riskPoints
      knowledgeProfile
      knowledgePoints
      userProfileSignature
      isKycApproved
      acceptTerms
      userAcceptTermsSignature
      customerStatus
      customerType
      customerCategory
      kycNextStep
      sf_Broker__c
      subaccounts {
        total_deposit_clp
        total_ransom_pen
        total_deposit_usd
        total_ransom_clp
        total_ransom_usd
        total_deposit_pen
        name
        currency
      }
      wasIdFileUploaded
      userIdFileSignature
      frontSideIdFilePath
      backSideIdFilePath
      wasAddressFileUploaded
      userAddressFileSignature
      addressFilePath
      wasCardFileUploaded
      userCardFileSignature
      cardFilePath
      wasLivenessFileUploaded
      confirmPhone
      wasPhoneValidate
      wasCreatedInMarketingCloud
      wasCreatedInSalesforce
      needKyc
      utmSource
      utmMedium
      utmCampaign
      utmId
      createdAt
      updatedAt
      validatePersonalInfo
    }
  }
`;
export const updateGoodTraderProfile = /* GraphQL */ `
  mutation UpdateGoodTraderProfile($id: ID!) {
    updateGoodTraderProfile(id: $id) {
      id
      cognitoId
      identityId
      salesforceId
      givenName
      familyName
      email
      phone
      birth
      dni
      signUpCountry
      originCountry
      residenceCountry
      city
      address
      job
      liveInUSA
      isPEP
      riskProfile
      riskPoints
      knowledgeProfile
      knowledgePoints
      userProfileSignature
      isKycApproved
      acceptTerms
      userAcceptTermsSignature
      customerStatus
      customerType
      customerCategory
      kycNextStep
      sf_Broker__c
      subaccounts {
        total_deposit_clp
        total_ransom_pen
        total_deposit_usd
        total_ransom_clp
        total_ransom_usd
        total_deposit_pen
        name
        currency
      }
      wasIdFileUploaded
      userIdFileSignature
      frontSideIdFilePath
      backSideIdFilePath
      wasAddressFileUploaded
      userAddressFileSignature
      addressFilePath
      wasCardFileUploaded
      userCardFileSignature
      cardFilePath
      wasLivenessFileUploaded
      confirmPhone
      wasPhoneValidate
      wasCreatedInMarketingCloud
      wasCreatedInSalesforce
      needKyc
      utmSource
      utmMedium
      utmCampaign
      utmId
      createdAt
      updatedAt
      validatePersonalInfo
    }
  }
`;
export const updateValidatePersonalInfo = /* GraphQL */ `
  mutation UpdateValidatePersonalInfo($id: ID!) {
    updateValidatePersonalInfo(id: $id) {
      id
      cognitoId
      identityId
      salesforceId
      givenName
      familyName
      email
      phone
      birth
      dni
      signUpCountry
      originCountry
      residenceCountry
      city
      address
      job
      liveInUSA
      isPEP
      riskProfile
      riskPoints
      knowledgeProfile
      knowledgePoints
      userProfileSignature
      isKycApproved
      acceptTerms
      userAcceptTermsSignature
      customerStatus
      customerType
      customerCategory
      kycNextStep
      sf_Broker__c
      subaccounts {
        total_deposit_clp
        total_ransom_pen
        total_deposit_usd
        total_ransom_clp
        total_ransom_usd
        total_deposit_pen
        name
        currency
      }
      wasIdFileUploaded
      userIdFileSignature
      frontSideIdFilePath
      backSideIdFilePath
      wasAddressFileUploaded
      userAddressFileSignature
      addressFilePath
      wasCardFileUploaded
      userCardFileSignature
      cardFilePath
      wasLivenessFileUploaded
      confirmPhone
      wasPhoneValidate
      wasCreatedInMarketingCloud
      wasCreatedInSalesforce
      needKyc
      utmSource
      utmMedium
      utmCampaign
      utmId
      createdAt
      updatedAt
      validatePersonalInfo
    }
  }
`;
export const updateRiskBadTraderProfile = /* GraphQL */ `
  mutation UpdateRiskBadTraderProfile($id: ID!) {
    updateRiskBadTraderProfile(id: $id) {
      id
      cognitoId
      identityId
      salesforceId
      givenName
      familyName
      email
      phone
      birth
      dni
      signUpCountry
      originCountry
      residenceCountry
      city
      address
      job
      liveInUSA
      isPEP
      riskProfile
      riskPoints
      knowledgeProfile
      knowledgePoints
      userProfileSignature
      isKycApproved
      acceptTerms
      userAcceptTermsSignature
      customerStatus
      customerType
      customerCategory
      kycNextStep
      sf_Broker__c
      subaccounts {
        total_deposit_clp
        total_ransom_pen
        total_deposit_usd
        total_ransom_clp
        total_ransom_usd
        total_deposit_pen
        name
        currency
      }
      wasIdFileUploaded
      userIdFileSignature
      frontSideIdFilePath
      backSideIdFilePath
      wasAddressFileUploaded
      userAddressFileSignature
      addressFilePath
      wasCardFileUploaded
      userCardFileSignature
      cardFilePath
      wasLivenessFileUploaded
      confirmPhone
      wasPhoneValidate
      wasCreatedInMarketingCloud
      wasCreatedInSalesforce
      needKyc
      utmSource
      utmMedium
      utmCampaign
      utmId
      createdAt
      updatedAt
      validatePersonalInfo
    }
  }
`;
export const updateTerms = /* GraphQL */ `
  mutation UpdateTerms($id: ID!, $acceptTerms: Boolean!) {
    updateTerms(id: $id, acceptTerms: $acceptTerms) {
      id
      cognitoId
      identityId
      salesforceId
      givenName
      familyName
      email
      phone
      birth
      dni
      signUpCountry
      originCountry
      residenceCountry
      city
      address
      job
      liveInUSA
      isPEP
      riskProfile
      riskPoints
      knowledgeProfile
      knowledgePoints
      userProfileSignature
      isKycApproved
      acceptTerms
      userAcceptTermsSignature
      customerStatus
      customerType
      customerCategory
      kycNextStep
      sf_Broker__c
      subaccounts {
        total_deposit_clp
        total_ransom_pen
        total_deposit_usd
        total_ransom_clp
        total_ransom_usd
        total_deposit_pen
        name
        currency
      }
      wasIdFileUploaded
      userIdFileSignature
      frontSideIdFilePath
      backSideIdFilePath
      wasAddressFileUploaded
      userAddressFileSignature
      addressFilePath
      wasCardFileUploaded
      userCardFileSignature
      cardFilePath
      wasLivenessFileUploaded
      confirmPhone
      wasPhoneValidate
      wasCreatedInMarketingCloud
      wasCreatedInSalesforce
      needKyc
      utmSource
      utmMedium
      utmCampaign
      utmId
      createdAt
      updatedAt
      validatePersonalInfo
    }
  }
`;
export const updateDeposit = /* GraphQL */ `
  mutation UpdateDeposit($id: ID!) {
    updateDeposit(id: $id) {
      id
      cognitoId
      identityId
      salesforceId
      givenName
      familyName
      email
      phone
      birth
      dni
      signUpCountry
      originCountry
      residenceCountry
      city
      address
      job
      liveInUSA
      isPEP
      riskProfile
      riskPoints
      knowledgeProfile
      knowledgePoints
      userProfileSignature
      isKycApproved
      acceptTerms
      userAcceptTermsSignature
      customerStatus
      customerType
      customerCategory
      kycNextStep
      sf_Broker__c
      subaccounts {
        total_deposit_clp
        total_ransom_pen
        total_deposit_usd
        total_ransom_clp
        total_ransom_usd
        total_deposit_pen
        name
        currency
      }
      wasIdFileUploaded
      userIdFileSignature
      frontSideIdFilePath
      backSideIdFilePath
      wasAddressFileUploaded
      userAddressFileSignature
      addressFilePath
      wasCardFileUploaded
      userCardFileSignature
      cardFilePath
      wasLivenessFileUploaded
      confirmPhone
      wasPhoneValidate
      wasCreatedInMarketingCloud
      wasCreatedInSalesforce
      needKyc
      utmSource
      utmMedium
      utmCampaign
      utmId
      createdAt
      updatedAt
      validatePersonalInfo
    }
  }
`;
export const skipDeposit = /* GraphQL */ `
  mutation SkipDeposit($id: ID!) {
    skipDeposit(id: $id) {
      id
      cognitoId
      identityId
      salesforceId
      givenName
      familyName
      email
      phone
      birth
      dni
      signUpCountry
      originCountry
      residenceCountry
      city
      address
      job
      liveInUSA
      isPEP
      riskProfile
      riskPoints
      knowledgeProfile
      knowledgePoints
      userProfileSignature
      isKycApproved
      acceptTerms
      userAcceptTermsSignature
      customerStatus
      customerType
      customerCategory
      kycNextStep
      sf_Broker__c
      subaccounts {
        total_deposit_clp
        total_ransom_pen
        total_deposit_usd
        total_ransom_clp
        total_ransom_usd
        total_deposit_pen
        name
        currency
      }
      wasIdFileUploaded
      userIdFileSignature
      frontSideIdFilePath
      backSideIdFilePath
      wasAddressFileUploaded
      userAddressFileSignature
      addressFilePath
      wasCardFileUploaded
      userCardFileSignature
      cardFilePath
      wasLivenessFileUploaded
      confirmPhone
      wasPhoneValidate
      wasCreatedInMarketingCloud
      wasCreatedInSalesforce
      needKyc
      utmSource
      utmMedium
      utmCampaign
      utmId
      createdAt
      updatedAt
      validatePersonalInfo
    }
  }
`;
export const updateIdFileUploaded = /* GraphQL */ `
  mutation UpdateIdFileUploaded(
    $id: ID!
    $uploaded: Boolean!
    $country: CountryType!
    $typeDocument: String!
    $kyc: Boolean
  ) {
    updateIdFileUploaded(
      id: $id
      uploaded: $uploaded
      country: $country
      typeDocument: $typeDocument
      kyc: $kyc
    ) {
      id
      cognitoId
      identityId
      salesforceId
      givenName
      familyName
      email
      phone
      birth
      dni
      signUpCountry
      originCountry
      residenceCountry
      city
      address
      job
      liveInUSA
      isPEP
      riskProfile
      riskPoints
      knowledgeProfile
      knowledgePoints
      userProfileSignature
      isKycApproved
      acceptTerms
      userAcceptTermsSignature
      customerStatus
      customerType
      customerCategory
      kycNextStep
      sf_Broker__c
      subaccounts {
        total_deposit_clp
        total_ransom_pen
        total_deposit_usd
        total_ransom_clp
        total_ransom_usd
        total_deposit_pen
        name
        currency
      }
      wasIdFileUploaded
      userIdFileSignature
      frontSideIdFilePath
      backSideIdFilePath
      wasAddressFileUploaded
      userAddressFileSignature
      addressFilePath
      wasCardFileUploaded
      userCardFileSignature
      cardFilePath
      wasLivenessFileUploaded
      confirmPhone
      wasPhoneValidate
      wasCreatedInMarketingCloud
      wasCreatedInSalesforce
      needKyc
      utmSource
      utmMedium
      utmCampaign
      utmId
      createdAt
      updatedAt
      validatePersonalInfo
    }
  }
`;
export const updateLivenessFileUploaded = /* GraphQL */ `
  mutation UpdateLivenessFileUploaded(
    $id: ID!
    $uploaded: Boolean!
    $kyc: Boolean
  ) {
    updateLivenessFileUploaded(id: $id, uploaded: $uploaded, kyc: $kyc) {
      id
      cognitoId
      identityId
      salesforceId
      givenName
      familyName
      email
      phone
      birth
      dni
      signUpCountry
      originCountry
      residenceCountry
      city
      address
      job
      liveInUSA
      isPEP
      riskProfile
      riskPoints
      knowledgeProfile
      knowledgePoints
      userProfileSignature
      isKycApproved
      acceptTerms
      userAcceptTermsSignature
      customerStatus
      customerType
      customerCategory
      kycNextStep
      sf_Broker__c
      subaccounts {
        total_deposit_clp
        total_ransom_pen
        total_deposit_usd
        total_ransom_clp
        total_ransom_usd
        total_deposit_pen
        name
        currency
      }
      wasIdFileUploaded
      userIdFileSignature
      frontSideIdFilePath
      backSideIdFilePath
      wasAddressFileUploaded
      userAddressFileSignature
      addressFilePath
      wasCardFileUploaded
      userCardFileSignature
      cardFilePath
      wasLivenessFileUploaded
      confirmPhone
      wasPhoneValidate
      wasCreatedInMarketingCloud
      wasCreatedInSalesforce
      needKyc
      utmSource
      utmMedium
      utmCampaign
      utmId
      createdAt
      updatedAt
      validatePersonalInfo
    }
  }
`;
export const updateAddressFileUploaded = /* GraphQL */ `
  mutation UpdateAddressFileUploaded($id: ID!, $uploaded: Boolean!) {
    updateAddressFileUploaded(id: $id, uploaded: $uploaded) {
      id
      cognitoId
      identityId
      salesforceId
      givenName
      familyName
      email
      phone
      birth
      dni
      signUpCountry
      originCountry
      residenceCountry
      city
      address
      job
      liveInUSA
      isPEP
      riskProfile
      riskPoints
      knowledgeProfile
      knowledgePoints
      userProfileSignature
      isKycApproved
      acceptTerms
      userAcceptTermsSignature
      customerStatus
      customerType
      customerCategory
      kycNextStep
      sf_Broker__c
      subaccounts {
        total_deposit_clp
        total_ransom_pen
        total_deposit_usd
        total_ransom_clp
        total_ransom_usd
        total_deposit_pen
        name
        currency
      }
      wasIdFileUploaded
      userIdFileSignature
      frontSideIdFilePath
      backSideIdFilePath
      wasAddressFileUploaded
      userAddressFileSignature
      addressFilePath
      wasCardFileUploaded
      userCardFileSignature
      cardFilePath
      wasLivenessFileUploaded
      confirmPhone
      wasPhoneValidate
      wasCreatedInMarketingCloud
      wasCreatedInSalesforce
      needKyc
      utmSource
      utmMedium
      utmCampaign
      utmId
      createdAt
      updatedAt
      validatePersonalInfo
    }
  }
`;
export const updateCardFileUploaded = /* GraphQL */ `
  mutation UpdateCardFileUploaded(
    $id: ID!
    $uploaded: Boolean!
    $kyc: Boolean!
    $ommit: Boolean!
  ) {
    updateCardFileUploaded(
      id: $id
      uploaded: $uploaded
      kyc: $kyc
      ommit: $ommit
    ) {
      id
      cognitoId
      identityId
      salesforceId
      givenName
      familyName
      email
      phone
      birth
      dni
      signUpCountry
      originCountry
      residenceCountry
      city
      address
      job
      liveInUSA
      isPEP
      riskProfile
      riskPoints
      knowledgeProfile
      knowledgePoints
      userProfileSignature
      isKycApproved
      acceptTerms
      userAcceptTermsSignature
      customerStatus
      customerType
      customerCategory
      kycNextStep
      sf_Broker__c
      subaccounts {
        total_deposit_clp
        total_ransom_pen
        total_deposit_usd
        total_ransom_clp
        total_ransom_usd
        total_deposit_pen
        name
        currency
      }
      wasIdFileUploaded
      userIdFileSignature
      frontSideIdFilePath
      backSideIdFilePath
      wasAddressFileUploaded
      userAddressFileSignature
      addressFilePath
      wasCardFileUploaded
      userCardFileSignature
      cardFilePath
      wasLivenessFileUploaded
      confirmPhone
      wasPhoneValidate
      wasCreatedInMarketingCloud
      wasCreatedInSalesforce
      needKyc
      utmSource
      utmMedium
      utmCampaign
      utmId
      createdAt
      updatedAt
      validatePersonalInfo
    }
  }
`;
export const updateIdentityId = /* GraphQL */ `
  mutation UpdateIdentityId($id: ID!, $identityId: ID!) {
    updateIdentityId(id: $id, identityId: $identityId) {
      id
      cognitoId
      identityId
      salesforceId
      givenName
      familyName
      email
      phone
      birth
      dni
      signUpCountry
      originCountry
      residenceCountry
      city
      address
      job
      liveInUSA
      isPEP
      riskProfile
      riskPoints
      knowledgeProfile
      knowledgePoints
      userProfileSignature
      isKycApproved
      acceptTerms
      userAcceptTermsSignature
      customerStatus
      customerType
      customerCategory
      kycNextStep
      sf_Broker__c
      subaccounts {
        total_deposit_clp
        total_ransom_pen
        total_deposit_usd
        total_ransom_clp
        total_ransom_usd
        total_deposit_pen
        name
        currency
      }
      wasIdFileUploaded
      userIdFileSignature
      frontSideIdFilePath
      backSideIdFilePath
      wasAddressFileUploaded
      userAddressFileSignature
      addressFilePath
      wasCardFileUploaded
      userCardFileSignature
      cardFilePath
      wasLivenessFileUploaded
      confirmPhone
      wasPhoneValidate
      wasCreatedInMarketingCloud
      wasCreatedInSalesforce
      needKyc
      utmSource
      utmMedium
      utmCampaign
      utmId
      createdAt
      updatedAt
      validatePersonalInfo
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      cognitoId
      identityId
      salesforceId
      givenName
      familyName
      email
      phone
      birth
      dni
      signUpCountry
      originCountry
      residenceCountry
      city
      address
      job
      liveInUSA
      isPEP
      riskProfile
      riskPoints
      knowledgeProfile
      knowledgePoints
      userProfileSignature
      isKycApproved
      acceptTerms
      userAcceptTermsSignature
      customerStatus
      customerType
      customerCategory
      kycNextStep
      sf_Broker__c
      subaccounts {
        total_deposit_clp
        total_ransom_pen
        total_deposit_usd
        total_ransom_clp
        total_ransom_usd
        total_deposit_pen
        name
        currency
      }
      wasIdFileUploaded
      userIdFileSignature
      frontSideIdFilePath
      backSideIdFilePath
      wasAddressFileUploaded
      userAddressFileSignature
      addressFilePath
      wasCardFileUploaded
      userCardFileSignature
      cardFilePath
      wasLivenessFileUploaded
      confirmPhone
      wasPhoneValidate
      wasCreatedInMarketingCloud
      wasCreatedInSalesforce
      needKyc
      utmSource
      utmMedium
      utmCampaign
      utmId
      createdAt
      updatedAt
      validatePersonalInfo
    }
  }
`;
